
import _ from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import ExporoMenu from '@/components/_common/Menu.vue';
import exporoPagesEnum from '@/enum/exporo/exporoPagesEnum';

@Component({
  name: 'app-sidebar',
  components: {
    ExporoMenu,
  },
})
export default class AppSidebar extends Vue {
  @Prop({ default: true, required: true }) menuOpen: Boolean;
  @Prop({ default: null, required: true }) toggleMenu: any;
  @Prop({ default: null, required: true }) signOut: any;
  @Prop({ default: '', required: true }) fullName: string;
  @Prop({ default: '', required: true }) exporoId: string;

  @Action('appState/startLoader') startLoader: Function;
  @Action('appState/stopLoader') stopLoader: Function;

  private languages: Array<string> = ['en-GB', 'de-DE'];
  private inTransition: Boolean = false;

  get exporoPages() {
    return _.values(exporoPagesEnum);
  }

  onChangeLanguage(locale) {
    this.startLoader();

    this.$i18n.changeLanguage(locale).then(() => {
      this.$forceUpdate();
      this.stopLoader();
    });
  }

  private onHover() {
    if (!this.inTransition && !this.menuOpen) {
      this.toggle();
    }
  }

  private onLeave() {
    if (!this.inTransition && this.menuOpen) {
      this.toggle();
    }
  }

  private toggle() {
    this.inTransition = true;
    setTimeout(() => (this.inTransition = false)), 700;
    this.toggleMenu();
  }

  private includesLink(url) {
    return window.location.href.includes(url);
  }

  private navigateToPage(url) {
    window.location.href = url;
  }
}
